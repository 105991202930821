import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../components/index.vue'
Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'index',
		component: index
	},
	{
		path: '/pj-about',
		name: 'about',
		component: () => import('../components/about.vue')
	},
	{
		path: '/pj-login',
		name: 'login',
		component: () => import('../components/login.vue')
	},
	{
		path: '/pj-register',
		name: 'register',
		component: () => import('../components/register.vue')
	},
	{
		path: '/pj-mechanism',
		name: 'mechanism',
		component: () => import('../components/mechanism.vue')
	},
	{
		path: '/pj-wanted',
		name: 'wanted',
		component: () => import('../components/wanted.vue')
	},
	{
		path: '/pj-postSet',
		name: 'postSet',
		component: () => import('../components/postSet.vue')
	},
	{
		path: '/pj-recruit',
		name: 'recruit',
		component: () => import('../components/recruit.vue')
	},
	{
		path: '/pj-post',
		name: 'post',
		component: () => import('../components/post.vue')
	},
	{
		path: '/pj-details',
		name: 'details',
		component: () => import('../components/details.vue')
	},
	{
		path: '/pj-company',
		name: 'company',
		component: () => import('../components/company.vue')
	},
	{
		path:'/pj-information',
		name: 'information',
		component: () => import('../components/information.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	// base: '/taotao/',
	routes
})
export default router
