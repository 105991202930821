import {
	enc,
	dec
} from "../../common/api/sec.js"
import store from "../../store/index.js"
import {
	pars,
	removeProperty
} from "./bytetostring.js"
import sha256 from 'crypto-js/sha256.js'
import md5 from 'js-md5'
import axios from 'axios'
import ElementUI from "element-ui";
import router from '../../router/index.js'
export default {
	common: {
		// baseUrl:"http://118.178.143.133:8086",
		//baseUrl: "http://192.168.1.3:8086",
		// baseUrl: "http://ujob.xuniuwang.com",
		baseUrl: "http://indexapi.jiabeitec.com",
		headers: {
			"token": '',
			"did": '',
			"sVer":2,
			"productId":"XUNNIU_JOB"
		},
		method: 'post',
		responseType: "arraybuffer",

	},
	request(options = {}) {
		this.common.headers.did = localStorage.getItem("did");
		this.common.headers.token = localStorage.getItem("token");
		options.url = this.common.baseUrl + options.url;
		options.method = this.common.method;
		if (options.headers == undefined) {
			options.headers = {}
			options.headers = Object.assign(options.headers, this.common.headers)
		} else {
			options.headers = Object.assign(options.headers, this.common.headers)
		}
		options.method = this.common.method;
		options.responseType = this.common.responseType;
		let jsonStr = JSON.stringify(options.data);

		let key
		let key2
		if (store.state.key) {
			key = store.state.key;
			key2 = store.state.key2
		} else {
			store.dispatch('initDic')
			key = store.state.key;
			key2 = store.state.key2
		}
		let data = enc(jsonStr, key)
		options.data = data.buffer;
		let value = md5(data);
		let obj = {},
			obj2 = {}
		let key1 = "data";
		obj[key1] = value;
		Object.assign(obj2, options.headers, obj)
		removeProperty(obj2)
		let autograph = pars(obj2)
		let shaArr = sha256(autograph + key2)
		let sign = "sign";
		let words = shaArr
		options.headers[sign] = words
		return new Promise((response, reject) => {
			axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
			axios(options).then(res => {
				
				let byteArray = new Uint8Array(res.data)
				let data = dec(byteArray, key);
				let dataObj = JSON.parse(data)
				if (dataObj.code == 203) {
					localStorage.removeItem('token');
					localStorage.removeItem('type');
					ElementUI.Message({
						title: '警告',
						message: dataObj.msg,
						type: 'warning'
					});
					router.push('./pj-login')
				} else if(dataObj.code == 200) {
					response(dataObj);
				}else{
					response(dataObj);
					ElementUI.Message({
						title: '警告',
						message: dataObj.msg,
						type: 'warning'
					});
				}
			}).catch(err => {
				reject(err)
			})
		})

	}
}
